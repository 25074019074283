<template>
    <div class="py-3">
        <b-row>
            <b-col>
                <badge :rounded="true" size="md" :type="state_badge_style_type()"
                    >{{ state_display_name }}
                </badge>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p class="mb-0">
                    {{ comment }}
                </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <small class="text-muted">
                    {{ display_date_time(created_date_time) }}
                </small>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "ApprovalSubmitterStateItem",
    components: {},
    props: {
        // Not implemented
        // user: {
        //     type: Object,
        //     description: "User object",
        //     default() {
        //         return {
        //             first_name: null,
        //             last_name: null,
        //             username: null,
        //         };
        //     },
        // },
        state_display_name: {
            type: String,
            description: "The new state display name.",
            default: null,
        },
        state_name: {
            type: String,
            description: "The new state  name.",
            default: null,
        },
        comment: {
            type: String,
            description: "The update comment",
            default: null,
        },
        created_date_time: {
            type: String,
            description: "The datetime that this was created.",
            default: null,
        },
        id: {
            type: String,
            description: "Id of the state update",
            default: null,
        },
    },
    methods: {
        display_date_time(date_string) {
            // Converts: to:
            let date = new Date(date_string);
            return (
                date.toISOString().split("T")[0] +
                " " +
                date.toISOString().split("T")[1].split(".")[0]
            );
        },
        state_badge_style_type() {
            if (this.state_name == "PENDING_SUBMISSION") {
                return "info";
            }
            if (this.state_name == "NEEDS_REVIEW") {
                return "warning";
            }
            if (this.state_name == "APPROVED") {
                return "info";
            }
            if (this.state_name == "SUBMITTED") {
                return "info";
            }
            return "danger";
        },
    },
};
</script>

<style></style>
