var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header pb-6 d-flex"},[_c('span',{staticClass:"mask bg-white opacity-8"}),_c('b-container',{staticClass:"d-flex",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"mt-4",attrs:{"sm":"12","md":"auto"}},[_c('div',{staticClass:"icon icon-shape rounded-circle shadow bg-primary text-white",on:{"click":function($event){return _vm.$router.back()}}},[_c('i',{staticClass:"fa fa-arrow-left"})])]),_c('b-col',{attrs:{"sm":"12","md":"auto"}})],1)],1)],1),_c('b-container',{staticClass:"mt--5",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"8"}},[_c('b-row',[_c('Transition',[(_vm.$apollo.queries.get_hackathon_idea_idea.loading)?_c('b-col',{staticClass:"d-flex align-items-center justify-content-center"},[_c('h1',[_c('i',{staticClass:"fas fa-spinner fa-spin ml-1"})])]):_c('b-col',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"sm":"auto"}},[_c('h1',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.idea.name)+" ")])]),_c('b-col',[(!_vm.team.is_member && !_vm.team.requested_to_join)?_c('base-button',{staticClass:"mt-1",attrs:{"type":"primary","pill":true,"native-":"submit","size":"sm","loading":_vm.join_button.loading,"success":_vm.join_button.success},on:{"click":function($event){return _vm.create_team_join_request()}}},[_vm._v("Join this team ")]):(_vm.team.requested_to_join)?_c('badge',{staticClass:"mt-1",attrs:{"rounded":true,"size":"md","type":"info"}},[_vm._v(" Requested to join team ")]):_vm._e()],1),_c('b-col',{staticClass:"text-right"},[(_vm.idea.is_creator)?_c('router-link',{attrs:{"to":{
                        name: 'HackathonRudIdea',
                        params: {
                          idea_id: _vm.idea.id,
                          hackathon_id: _vm.hackathon.id,
                        },
                      }}},[_c('i',{staticClass:"fas fa-edit text-black-50"})]):_vm._e()],1)],1),_c('b-row',[_c('b-col',[_c('h5',[_vm._v(_vm._s(_vm.idea.description))])])],1),_c('b-row',[_c('b-col',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.idea.detail_description)}})])],1)],1)],1)],1)],1),(_vm.idea.is_creator && _vm.idea.hackathon.ideas_require_approval)?_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('HackathonAiIdeaFeedback')],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('Transition',[(
                  _vm.team.member_count > 0 &&
                  !_vm.$apollo.queries.get_hackathon_idea_idea.loading
                )?_c('HackathonTeamMembers',{attrs:{"team_id":_vm.team.id,"view_only":""}}):_vm._e()],1)],1)],1)],1),(_vm.idea.is_creator && _vm.idea.hackathon.ideas_require_approval)?_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-row',[_c('b-col',[_c('ApprovalSubmitter',{attrs:{"approval_id":_vm.idea.approval.id,"show_history":""}})],1)],1)],1):_vm._e(),(_vm.idea.is_creator && !_vm.idea.hackathon.ideas_require_approval)?_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-row',[_c('b-col',[_c('HackathonAiIdeaFeedback')],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }